<template>
  <div>
    <span class="text-center table-edit-icon" @click="popupActive = true">
      <i
        v-if="params.data.contract_active == 4"
        class="text-center material-icons"
        >unarchive</i
      >
      <i v-else class="text-center material-icons">archive</i>
    </span>
    <div class="demo-alignment">
      <vs-popup
        :id="'popmodalA' + params.data.user_id"
        :button-close-hidden="true"
        class="holamundo p-2"
        title="User Status"
        :active.sync="popupActive"
      >
        <div class="text-center">
          <h5 v-if="params.data.contract_active == '4'">
            Do you want to unarchive?
          </h5>
          <h5 v-else-if="params.data.contract_active == '1' || '2'">
            Do you want to archive?
          </h5>
          <!-- SUBMIT BUTTON -->
          <div class="vx-row mt-5">
            <div class="vx-col mx-auto">
              <!-- :disabled="submitStatus" -->
              <vs-button
                color="success"
                type="filled"
                @click="onChangeCheckbox($event)"
                class="mr-3"
                >Yes</vs-button
              >

              <vs-button color="danger" type="filled" @click="privStatus()"
                >No</vs-button
              >
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
  <!--  -->
</template>

<script>
import Vue from "vue";
import userService from "@/services/UserService.js";

export default Vue.extend({
  data() {
    return {
      params: null,
      popupActive: false,
      form: {
        contract_active: "4"
      },
      copy_contract_active: null
    };
  },
  beforeMount() {
    this.form = this.params.data;
  },

  beforeDestroy() {
    let _id = document.getElementById("popmodalA" + this.params.data.user_id);
    console.log("_id", _id);
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    onChangeCheckbox: function(e, value) {
      this.$vs.loading();

      console.log("this.params", this.params);
      let id = this.params.data.user_id;

      let payload = {
        user_mobile: this.form.user_mobile,
        user_email: this.form.user_email,
        role_id: this.form.role_id,
        user_address: this.form.user_address,
        city_id: this.form.city_id,
        user_dob: this.form.user_dob,
        user_pic: this.form.user_pic,
        user_active: this.form.user_active,

        user_name: this.form.user_name,
        user_gender: this.form.user_gender,
        contract_level: this.form.contract_level,
        contract_active: this.params.data.contract_active == "4" ? "1" : "4"
      };
      console.log(payload, "fsdfdsf");
      userService
        .EditUser(payload, id)
        .then(response => {
          this.$vs.loading.close();
          const { data } = response;
          if (!data.error) {
            this.$vs.notify({
              title: "Updated!",
              text: data.data.message,
              iconPack: "feather",
              icon: "check_box",
              color: "success"
            });
            setTimeout(() => {
              eventBus.$emit("refreshuserTable", data);
            }, 1);
            this.popupActive = false;
          } else {
            this.$vs.loading.close();
            console.log("data :", data);
            this.$vs.notify({
              title: "Error!",
              text: data.error.message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning"
            });
          }
        })
        .catch(err => {
          this.$vs.loading.close();
        });
    },
    privStatus: function() {
      this.popupActive = false;
      this.form.contract_active = this.copy_contract_active;
    }
  }
});
</script>
