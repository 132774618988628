import Api from './Api.js';
const organization_id = () => {
  return window.localStorage.getItem("OrganizationID");
};


export default {
  addUserRole(payload) {
    return Api().post(`/v2/userrole?organization_id=${organization_id()}`, payload);
  },
  editUserRole(payload, id) {
    return Api().put(`/v2/userrole/${id}?organization_id=${organization_id()}`, payload);
  },

  getAllUserRoles(filters, sort) {
    return Api().get(`/v2/userrole?organization_id=${organization_id()}`);
  },

  getUserRole(payload, id) {
    return Api().get(`/v2/userrole/${id}?organization_id=${organization_id()}`, payload);
  },
};
