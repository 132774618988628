<template>
  <div>
    <span class="text-center table-edit-icon" @click="showModal">
      <i class="text-center material-icons">edit</i>
    </span>
    <div class="centerx">
      <vs-popup
        :id="'popmodal' + params.data.user_id"
        class="holamundo editModal"
        title="Update User"
        :active.sync="popupActive"
      >
        <form ref="testingRef">
          <FocusLock>
            <div class="vx-row">
              <!-- LEFT SIDE -->
              <!-- name -->
              <div class="col-12">
                <div class="vx-row mb-6">
                  <div class="col-4 text-right align-self-center">
                    <h5 class="mb-0">Name</h5>
                  </div>
                  <div class="col-7">
                    <div class>
                      <vs-input
                        v-validate="'required'"
                        name="Name"
                        v-model="form.user_name"
                        :disabled="checkContractUser"
                        class="w-full"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Name')"
                        >{{ errors.first("Name") }}</span
                      >
                    </div>
                  </div>
                </div>
                <!-- email-->
                <div class="vx-row mb-6">
                  <div class="col-4 text-right align-self-center">
                    <h5 class="mb-0">Email *</h5>
                  </div>
                  <div class="col-7">
                    <div class>
                      <!-- v-validate="'required'" -->
                      <vs-input
                        disabled
                        name="Email"
                        v-model="form.user_email"
                        class="w-full"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('name')"
                        >{{ errors.first("name") }}</span
                      >
                    </div>
                  </div>
                </div>
                <!-- Phone -->
                <div class="vx-row mb-6">
                  <div class="col-4 text-right align-self-center">
                    <h5 class="mb-0">Phone *</h5>
                  </div>
                  <div class="col-7">
                    <div class>
                      <vs-input
                        v-validate="'required|numeric|max:14'"
                        name="Phone"
                        v-model="form.user_mobile"
                        class="w-full"
                        rules="alpha"
                        :disabled="checkContractUser"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Phone')"
                        >{{ errors.first("Phone") }}</span
                      >
                    </div>
                  </div>
                </div>
                <!-- address  -->
                <div class="vx-row mb-6">
                  <div class="col-4 text-right align-self-center">
                    <h5 class="mb-0">Address *</h5>
                  </div>
                  <div class="col-7">
                    <div class>
                      <vs-textarea
                        v-model="form.user_address"
                        class="mb-0"
                        rows="4"
                        :disabled="checkContractUser"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Address')"
                        >{{ errors.first("Address") }}</span
                      >
                    </div>
                  </div>
                </div>
                <!-- gender -->
                <div class="vx-row mb-6">
                  <div class="col-4 text-right align-self-center">
                    <h5 class="mb-0">Gender*</h5>
                  </div>
                  <div class="col-7">
                    <ul class="centerx list-inline">
                      <li class="list-inline-item">
                        <vs-radio
                          v-model="form.user_gender"
                          vs-value="Male"
                          :disabled="checkContractUser"
                          >Male</vs-radio
                        >
                      </li>
                      <li class="list-inline-item">
                        <vs-radio
                          v-model="form.user_gender"
                          vs-value="Female"
                          :disabled="checkContractUser"
                          >Female</vs-radio
                        >
                      </li>
                    </ul>
                  </div>
                </div>

                <!-- DOB-->
                <div class="vx-row mb-6">
                  <div class="col-4 text-right">
                    <h5 class="mb-0">Date of birth</h5>
                  </div>
                  <div class="col-7">
                    <div class="rounded">
                      <!-- class="bg-white" -->
                      <flat-pickr
                        v-model="form.user_dob"
                        :config="configDOB"
                        :disabled="checkContractUser"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('Birth date')"
                      >{{ errors.first("birth date") }}</span
                    >
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-4 text-right align-self-center">
                    <h5 class="mb-0">Add Level</h5>
                  </div>
                  <div class="col-7">
                    <div class>
                      <!-- v-validate="'required'" -->
                      <vs-input
                        name="Level"
                        v-model="form.contract_level"
                        class="w-full text-danger"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Level')"
                        >{{ errors.first("Level") }}</span
                      >
                    </div>
                  </div>
                </div>

                <!-- Password -->
                <div class="vx-row mb-6">
                  <div class="col-4 text-right align-self-center">
                    <h5 class="mb-0">Password</h5>
                  </div>
                  <div class="col-7">
                    <div class>
                      <!-- v-validate="'required'" -->
                      <vs-input
                        name="Password"
                        v-model="user_password"
                        class="w-full text-danger"
                        :disabled="checkContractUser"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('new user password')"
                        >{{ errors.first("new user password") }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- SUBMIT BUTTON -->
            <div class="vx-row">
              <div class="vx-col mx-auto">
                <!-- :disabled="submitStatus" -->
                <vs-button
                  :disabled="submitStatus"
                  type="filled"
                  @click.prevent="submitForm"
                  class="mr-3"
                  >Update</vs-button
                >

                <!-- <vs-button color="warning" type="border" class @click.prevent="clearForm">Reset</vs-button> -->
              </div>
            </div>
          </FocusLock>
        </form>
      </vs-popup>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import UserService from "@/services/UserService.js";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
export default Vue.extend({
  components: {
    flatPickr
  },
  computed: {
    checkContractUser() {
      if (this.params.data.employment_active == 1) {
        return true;
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      configDOB: {
        maxDate: new Date(),
        dateFormat: "d-m-Y"
        // altFormat: "d-m-Y",
        // altInput: true
      },
      submitStatus: true,
      isPasswordUpdated: false,
      form: {
        user_name: "",
        user_email: "",
        user_mobile: null,
        user_address: "",
        city_id: null,
        user_gender: "",
        user_dob: "",
        role_id: null,
        contract_level: ""
      },
      user_password: null,
      users: [],
      popupActive: false,
      params: null,
      value: null,
      count: 0
    };
  },
  watch: {
    form: {
      handler: function(newVal, oldVal) {
        if (this.count > 0) {
          this.submitStatus = false;
        }
        this.count++;
      },
      deep: true
    },
    user_password: {
      handler: function(newVal, oldVal) {
        if (this.count > 0) {
          this.isPasswordUpdated = true;
          this.submitStatus = false;
        }
        this.count++;
      },
      deep: true
    }
  },
  beforeMount() {
    this.value = this.params.value;
    this.form = this.params.data;
  },
  beforeDestroy() {
    let _id = document.getElementById("popmodal" + this.params.data.user_id);
    console.log("_id", _id);
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    showModal() {
      this.popupActive = true;
    },
    submitForm() {
      // delete this.form.user_email;
      console.log("object", this.form);
      this.$validator.validateAll().then(result => {
        this.$vs.loading();
        // console.log("result", result);
        if (result) {
          let id = this.params.data.user_id;
          let payload = {
            user_mobile: this.form.user_mobile,
            user_email: this.form.user_email,
            role_id: this.form.role_id,
            user_address: this.form.user_address,
            city_id: this.form.city_id,
            user_dob: this.form.user_dob,
            user_pic: this.form.user_pic,
            user_active: this.form.user_active,

            user_name: this.form.user_name,
            user_gender: this.form.user_gender,
            contract_level: this.form.contract_level,
            contract_active: this.form.contract_active
          };
          //   close;
          console.log("this.isPasswordUpdated", this.isPasswordUpdated);
          if (this.isPasswordUpdated) {
            payload["user_password"] = this.user_password;
          }
          UserService.EditUser(payload, id)
            .then(response => {
              this.$vs.loading.close();
              this.submitStatus = false;
              const { data } = response;
              this.popupActive = false;
              if (!data.error) {
                this.$vs.notify({
                  title: "Updated!",
                  text: data.data.message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "success"
                });
                setTimeout(() => {
                  eventBus.$emit("refreshuserTable", data);
                }, 1);
                this.clearForm();
              } else {
                this.$vs.loading.close();
                console.log("data :", data);
                this.$vs.notify({
                  title: "Error!",
                  text: data.error.message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning"
                });
              }
            })
            .catch(error => {
              this.$vs.loading.close();
              this.submitStatus = true;
              console.log("error:", error);
            });
        } else {
          //form have errors
        }
      });
    },
    getUserList: function() {
      // console.log("RolesService :", RolesService);
      UserService.editUser()
        .then(response => {
          const { data } = response;
          if (data.err) {
          } else {
            this.users = data.data;
          }
        })
        .catch(error => {
          console.log("error :", error);
        });
    },
    clearForm: function() {
      Object.keys(this.form).map((x, index) => {
        /_active/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
      });
      this.$validator.reset();
    }
  }
});
</script>
