export default {
  DEFAULT_IMAGE: {
    thumbnailWidth: 150,
    maxFilesize: 15,
    maxFiles: 1,
    acceptedFiles: "image/*"
  },

    DEFAULT_FILES: {
        thumbnailWidth: 150,
        maxFilesize: 50,
        maxFiles: 1,
        acceptedFiles: ".pdf,.xls,.xlsx,.csv,.txt"
    },
};
